import './Auth.css'

function Auth() {
    return (
        <div className="Auth">
                <div className="button" id="button">
                    <div id="circle"></div>
                    <a href="https://api.intra.42.fr/oauth/authorize?client_id=5e692c36b33f788774145d616b6e961882b389688fa1b0b118d7fff200a3b75d&redirect_uri=https%3A%2F%2F13stats.strix.moe%2F&response_type=code">Sign in ツ</a>
                </div>
        </div>
    )
}

export default Auth;