import React, { useEffect, useRef, clearCookie } from 'react';
import Chart from 'chart.js/auto';
import './App.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useState } from 'react';
import Auth from './Auth';
import axios from 'axios';

const ResponsiveLineChart = ({ data, options, chartType }) => {
	const chartRef = useRef(null);
	useEffect(() => {
		const ctx = chartRef.current.getContext('2d');
		let chartInstance = null;

		const createChart = () => {
			if (chartInstance) {
				chartInstance.destroy();
			}

			chartInstance = new Chart(ctx, {
				type: chartType == 1 ? 'doughnut' : 'line', //line
				data,
				options: {
					...options,
					responsive: true,
					maintainAspectRatio: false,
				},
			});
		};

		createChart();

		return () => {
			if (chartInstance) {
				chartInstance.destroy();
				chartInstance = null;
			}
		};
	}, [data, options]);

	return <canvas ref={chartRef} />;
};

const App = () => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [startDate, setStartDate] = useState(new Date());
	const [chartType, setChartType] = useState(2)
	const [e1Data, setE1Data] = useState([]);
	const [e2Data, setE2Data] = useState([]);
	const [e3Data, setE3Data] = useState([]);
	const [timeIntervals, setTimeIntervals] = useState([]);
  	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
    	const code = urlParams.get('code');
    	axios.post("https://13stats.strix.moe/auth", {
			code,	
		},
		{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			withCredentials: true
		}).then(async response => {
        	setData(response.data);
			if (!response.data.authorized)	
				return ;
			setLoading(true)
			await axios.get("https://13stats.strix.moe/data", {
				params: {
					date: startDate.toISOString().split('T')[0] + 'T00:00:00.000Z'
				},
				withCredentials: true,
			}).then(r => {
				setE1Data(r.data.data[0])
				setE2Data(r.data.data[1])
				setE3Data(r.data.data[2])
				setTimeIntervals(r.data.data[3])
				setLoading(false);
				
			})
		}).catch(error => {
    		setError(error);
    		setLoading(false);
    	});
  	}, [startDate]);
	  if (error) {
		  return (<Auth />)
		}
		if (loading)
			return (
				<div className='spinner'>
					<div className="lds-dual-ring"></div>
					<p>Loading</p>
				</div>
			)
		if (!loading) {
		
		const datasets = chartType == 1 ? [
			{
				label: 'Number of students',
				data: timeIntervals,
				borderColor: 'rgb(26,26,59)',
				backgroundColor: [
					'rgb(255, 99, 132)',
					'rgb(54, 162, 235)',
					'rgb(255, 206, 86)',
					'rgb(75, 192, 192)',
					'rgb(153, 102, 255)',
					'rgb(255, 159, 64)',
				],
				pointStyle: 'circle',
				pointRadius: 6,
				pointHoverRadius: 10
			},
		]: [
			{
				label: 'E1',
				data: e1Data,
				borderColor: 'rgb(255, 99, 132)',
				backgroundColor: [
					'rgb(255, 99, 132)'
				],
				pointStyle: 'circle',
				pointRadius: 6,
				pointHoverRadius: 10
			},
			{
				label: 'E2',
				data:  e2Data,
				borderColor: 'rgb(255, 159, 64)',
				backgroundColor: [
					'rgb(255, 159, 64)'
				],
				pointStyle: 'circle',
				pointRadius: 6,
				pointHoverRadius: 10
			},
			{
				label: 'E3',
				data: e3Data,
				borderColor: 'rgb(75, 192, 192)',
				backgroundColor: [
					'rgb(75, 192, 192)'
				],
				pointStyle: 'circle',
				pointRadius: 6,
				pointHoverRadius: 10
			}
		]
		const chartData = {
			labels: chartType == 2 ? ['00 PM', '01 PM', '02 PM', '03 PM', '04 PM', '05 PM', '06 PM', '07 PM', '08 PM', '09 PM', '10 PM', '11 PM', '12 PM', '13 PM', '14 PM', '15 PM', '16 PM', '17 PM', '18 PM', '19 PM', '20 PM', '21 PM', '22 PM', '23 PM', '24 PM']
								   : ['0h - 4h', '4h - 6h', '6h - 8h', '8h - 10h', '10h+'],
			datasets
		};
	
		const chartOptions = {
		
		};
		const handleChartTypeChange = (event) => {
			setChartType(Number(event.target.value));
		};
		const logout = () => {
			setLoading(false)
			document.cookie = 'Authorization=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
    		window.location.reload()
		}
		return (
			<div className='App'>
				<div className='Page'>
					<ResponsiveLineChart data={chartData} options={chartOptions} chartType={chartType} />
					<DatePicker className='datePicker' selected={startDate} onChange={(date) => setStartDate(date)} />
					<div className='inputs'>
						<input
							className="chartType"
							type="radio"
							id="option1"
							value="1"
							checked={chartType === 1}
							onChange={handleChartTypeChange}
						/>
						<label htmlFor="option1">Average length of sessions</label>
			
						<input
							className="chartType"
							type="radio"
							id="option2"
							value="2"
							checked={chartType === 2}
							onChange={handleChartTypeChange}
						/>
						<label htmlFor="option2">Number of posts used per hour</label>
					</div>
					<p> Logged in as <span>{data.data.login}</span></p>
					<a onClick={logout}>Logout</a>
				</div>
			</div>
		);
	}

	
}

export default App;
